<template>
  <div class="m-2 px-4 pb-2 survey-card">
    <template v-if="allows_crud">
      <div class="row m-1">
        <b-button
          size="sm"
          class="m-1"
          @click="$bvModal.show(`modal-update-question-${survey_question.id}`)"
          >Editar Pregunta</b-button
        >
        <b-button size="sm" class="m-1" @click="launchCreateOptionModal"
          >Agregar Opción</b-button
        >
      </div>
    </template>
    <div class="row">
      {{ order ? order + ". " : "" }}{{ survey_question.title }}
    </div>
    <div v-if="survey_question.description" class="m-1 well">
      <p class="text-justify">{{ survey_question.description }}</p>
    </div>
    <div>
      <template v-for="option in options">
        <div :key="option.id">
          <div class="d-flex justify-content-between my-1">
            <div><b-form-checkbox></b-form-checkbox></div>
            <div class="w-50 mw-75 text-left">{{ option.title }}</div>
            <div v-if="allows_crud">Puntaje: {{ option.score }}</div>
            <div v-if="allows_crud" class="mx-1">
              {{
                option.to_argue
                  ? "Requiere argumentación"
                  : "No requiere argumentación"
              }}
            </div>
            <div v-if="allows_crud" class="text-right">
              <b-button size="sm" @click="launchUpdateOptionModal(option)"
                >Editar Opción</b-button
              >
            </div>
          </div>
          <div v-if="option.description">{{ option.description }}</div>
        </div>
      </template>
    </div>

    <!-- Modals -->
    <b-modal
      :id="`modal-update-question-${survey_question.id}`"
      title="Editar Pregunta"
      hide-footer
      size="md"
    >
      <SurveyQuestionForm
        :SurveyQuestion="survey_question"
        @updated="slotUpdatedQuestion"
      ></SurveyQuestionForm>
    </b-modal>
    <b-modal
      :id="`modal-question-option-${survey_question.id}`"
      :title="selected_option ? 'Editar Opción' : 'Crear Opción'"
      hide-footer
      size="md"
    >
      <QuestionOptionForm
        :QuestionOption="selected_option"
        :survey_question_id="survey_question.id"
        @created="slotCreatedOption"
        @updated="slotUpdatedOption"
      ></QuestionOptionForm>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "SurveyQuestion",
  components: {
    SurveyQuestionForm: () => import("./SurveyQuestionForm"),
    QuestionOptionForm: () => import("./QuestionOptionForm"),
  },
  props: {
    SurveyQuestion: {
      type: Object,
      required: true,
    },
    order: {
      type: Number,
    },
    QuestionTypes: {
      type: Array,
      default: function () {
        return [
          { id: 1, value: "Simple" },
          { id: 2, value: "Multiple" },
        ];
      },
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      survey_question: this.SurveyQuestion,
      options: [],
      selected_option: null,
    };
  },
  methods: {
    fetchOptions() {
      this.$restful
        .Get(`/cideu/survey-option/?survey_question=${this.survey_question.id}`)
        .then((response) => {
          this.options = response;
        });
    },
    launchCreateOptionModal() {
      this.selected_option = null;
      this.$bvModal.show(`modal-question-option-${this.survey_question.id}`);
    },
    launchUpdateOptionModal(option) {
      this.selected_option = option;
      this.$bvModal.show(`modal-question-option-${this.survey_question.id}`);
    },
    slotCreatedOption(option) {
      this.options.push(option);
      this.$bvModal.hide(`modal-question-option-${this.survey_question.id}`);
    },
    slotUpdatedOption(option) {
      const index = this.options.findIndex((x) => x.id == option.id);
      if (index != -1) {
        this.options.splice(index, 1, option);
      }
      this.$bvModal.hide(`modal-question-option-${this.survey_question.id}`);
    },
    slotUpdatedQuestion(question) {
      this.$bvModal.hide(`modal-update-question-${this.survey_question.id}`);
      this.survey_question = question;
      this.$emit("updated", question);
    },
  },
  created() {
    this.fetchOptions();
  },
};
</script>

<style scoped>
</style>